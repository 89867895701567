import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen bg-main-black flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold text-soft-cloud mb-4">404</h1>
      <p className="text-cadet-gray mb-4">Page not found</p>
      <p className="text-medium-gray">Redirecting you to the homepage...</p>
    </div>
  );
};

export default NotFoundPage;
