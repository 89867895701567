import React from "react";
import Navbar from "../components/Navbar";

const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-main-black">
      <Navbar />
      <main className="max-w-[2000px] mx-auto px-6">
        <div className="mt-16 md:mt-24 mb-16">
          <h1 className="text-5xl md:text-7xl font-bold leading-tight text-soft-cloud">
            Upload once to
            <br />
            your every
            <br className="md:hidden" /> account
          </h1>
          <p className="text-lg md:text-xl mt-6 md:mt-8 text-synced-blue max-w-2xl">
            Upload your videos or pod casts to all, or any of your channels with
            a single click.
          </p>
        </div>
      </main>
    </div>
  );
};

export default LandingPage;
